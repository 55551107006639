<template>
	<div class="glossary">
		<form class="form">
			<div class="form-group">
				<label>Term</label>

				<div class="form-group-input">
					<input
						type="text"
						size="50"
						maxlength="50"
						class="text"
						v-model="searchForm.term"
						@keyup="delay('liveSearch', 'term', 400)"
						@keyup.enter="submit()"
						@click="setSearchElement"
						style="font-size: 16px"
					/>
					<button
						type="button"
						class="form-group-input-clear"
						:style="!searchForm.term.length ? 'opacity: 0;' : null"
						@click="searchForm.term = ''"
					>
						X
					</button>
					<div v-if="(searchResults.length > 0) & (windowY <= 120)" class="form-group-input-results">
						<span
							v-for="(text, i) in searchResults"
							:key="i"
							class="form-group-input-results-item"
							@mousedown="
								text !== 'No Matches Found'
									? ((searchForm.term = text), (searchResults = []))
									: null
							"
							v-html="highlightText('term', text)"
						></span>
					</div>
				</div>

				<button type="button" class="form-btn ml-2" @click="submit()">Search</button>
				<button type="button" class="form-btn ml-2" @click="clear()">Clear</button>
			</div>

			<div class="glossary-result">
				<RichText :textContent="searchResults.definition" :interactive="false" />
			</div>
		</form>
	</div>
</template>

<script>
import RichText from "@/components/RichText.vue";
export default {
	name: "Glossary",
	components: {
		RichText,
	},
	data() {
		return {
			searchForm: {
				term: "",
			},
			searchResults: {
				term: "",
				definition: "",
			},
			loadingResults: false,
			resultsError: false,
			windowY: 0,
			formError: false,
		};
	},
	methods: {
		async submit() {
			this.resultsError = false;
			this.loadingResults = true;

			//clear out live results to hide them on search and unblur the input
			event.target.blur();
			this.searchResults = { term: "", definition: "" };

			try {
				let searchQuery = {
					searchTerm: this.searchForm.term,
				};

				const res = await this.$http.post(`/api/search/glossary-term`, searchQuery);
				console.log(res);

				this.searchResults = res.data.searchResults.glossaryTerm;
				this.loadingResults = false;
			} catch (error) {
				console.log(error.response);
				if (error.response.status == 404) {
					this.resultsError = true;
					this.loadingResults = false;
				}
			}
		},

		setSearchElement(event) {
			if (this.searchElement == event.target) {
				return;
			} else {
				this.searchElement = event.target;

				//reset live search results if search element changes
				this.searchResults = [];
			}
		},

		hideLiveSearch(event) {
			//if any of the live searchs have results hide and clear all results if user clicks away from the results
			if (this.searchResults.length > 0) {
				if (
					event.target.classList.contains("form-group-input-results") ||
					event.target.classList.contains("form-group-input-results-item") ||
					event.target == this.searchElement
				) {
					return;
				} else {
					this.searchResults = [];
				}
			}
		},

		highlightText(type, str) {
			if (str == "No Matches Found") return str;

			if (type == "term") {
				const htmlString = this.divide_and_conquer_replace(this.searchForm.term, str, " ");
				return htmlString;
			}
		},

		divide_and_conquer_replace(query, option, separator) {
			let terms, terms_esc;

			//The inner replacement function
			function divide_and_conquer_inner(bites, depth) {
				let this_term, i, bite, match, new_bites, found_all_others;

				depth = depth ? depth : 1;

				//Get the longest remaining term
				this_term = terms_esc[terms_esc.length - depth];

				//Loop all the bites
				for (i = 0; i < bites.length; i++) {
					bite = bites[i];

					//Reset the lastIndex since we're reusing the RegExp objects
					this_term.lastIndex = 0;

					//Check that we have a string (ie. do not attempt to match bites
					//that are already consumed)
					if (typeof bite === "string") {
						//Find the next matching position (if any)
						while ((match = this_term.exec(bite)) !== null) {
							new_bites = i > 0 ? bites.slice(0, i) : [];
							if (match.index > 0) {
								new_bites.push(bite.slice(0, match.index));
							}
							new_bites.push(["<b>" + match[0] + "</b>"]);
							if (this_term.lastIndex < bite.length) {
								new_bites.push(bite.slice(this_term.lastIndex));
							}
							if (i < bites.length - 1) {
								new_bites = new_bites.concat(bites.slice(i + 1));
							}

							if (terms_esc.length > depth) {
								//Attempt to find all other terms
								found_all_others = divide_and_conquer_inner(new_bites, depth + 1);

								//If we found all terms we'll pass the modified string all the
								//way up to the original callee
								if (found_all_others) {
									return found_all_others;
								}
								//Otherwise try to match current term somewhere else
								this_term.lastIndex = match.index + 1;
							} else {
								//If no terms remain we have a match
								return new_bites.join("");
							}
						}
					}
				}
				//If we reach this point at least one term was not found
				return null;
			}

			// Split query in terms at delimiter
			terms = query.split(separator).filter(Boolean);
			if (!terms.length) return option;

			//Sort terms according to length - longest term last
			terms.sort((a, b) => a.length - b.length);

			//Escape terms and store RegExp's instead of strings
			terms_esc = terms
				.map((term) => term.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"))
				.map((term) => new RegExp(term, "gi"));

			return divide_and_conquer_inner([option]);
		},

		delay(func, type, time) {
			if (this.timer) {
				clearTimeout(this.timer);
				this.timer = null;
			}
			this.timer = setTimeout(() => {
				this[func](type);
			}, time);
		},

		async liveSearch(type) {
			//cancel live search if currentlly loading results
			if (this.loadingResults) {
				this.searchResults = [];
				return;
			}

			try {
				if (type == "term") {
					if (this.searchForm.term == "") {
						this.searchResults = [];
					} else {
						const res = await this.$http.get(`/api/suggest/glossary-term`, {
							params: { searchTerm: this.searchForm.term },
						});
						this.searchResults = res.data;
					}
				}

				if (this.loadingResults) {
					this.searchResults = [];
				}
			} catch (error) {
				if (error.response.status == 404) {
					if (type == "term") {
						this.formulationResults = ["No Matches Found"];
					}
				}
				console.log(error);
			}
		},

		clear() {
			this.searchForm = {
				term: "",
			};
			this.searchResults = {
				term: "",
				definition: "",
			};
			this.formError = false;
			this.windowY = 0;
		},
	},
	created() {
		window.addEventListener("scroll", this.watchScroll);

		//add click listener to handle showing/hiding live search box
		window.addEventListener("click", this.hideLiveSearch);
	},
	destroyed() {
		window.removeEventListener("scroll", this.watchScroll);
		window.removeEventListener("click", this.hideLiveSearch);
	},
};
</script>

<style lang="scss" scoped>
.glossary {
	position: relative;

	&-result {
		margin-top: 1rem;
		font-size: 13px;
	}

	.form-group {
		label {
			width: 60px;
		}
	}
}
</style>
